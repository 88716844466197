.nm-module-offer-teaser {
	margin: 0 auto var(--space-xxxl);
	width: 92%;
}

.nm-module-offer-teaser .nm-offer-teaser-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-xl);
}

.nm-module-offer-teaser .nm-offer-teaser-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.nm-module-offer-teaser .nm-offer-teaser-item-link {
	width: 100%;
}

.nm-module-offer-teaser .nm-offer-teaser-list:not(.nm-offer-teaser-list-1) .nm-offer-teaser-item-link {
	margin-bottom: var(--space-xl);
}

.nm-module-offer-teaser .nm-offer-teaser-list:not(.nm-offer-teaser-list-1) .nm-offer-teaser-item-link:last-child {
	margin-bottom: 0;
}

.nm-module-offer-teaser .nm-offer-teaser-item-image {
	height: 0;
	margin-bottom: var(--space-xs);
	overflow: hidden;
	padding-bottom: 56.25%;
	width: auto;
}

.nm-module-offer-teaser__item-image-asset {
	height: auto;
	position: relative;
	width: 100%;
}

.nm-module-offer-teaser__asset-disclaimer {
	margin: var(--space-s) 0;
}

.nm-module-offer-teaser__item--empty-disclaimer .nm-module-offer-teaser__asset-disclaimer {
	display: none;
}

.nm-module-offer-teaser .nm-offer-teaser-item-copy {
	margin-bottom: var(--space-s);
	width: calc(100% - var(--space-xl));
}

@media all and (min-width: 480px) {
	.nm-module-offer-teaser .nm-offer-teaser-list.nm-offer-teaser-list-1 .nm-offer-teaser-item-link,
	.nm-module-offer-teaser .nm-offer-teaser-list.nm-offer-teaser-list-2 .nm-offer-teaser-item-link,
	.nm-module-offer-teaser .nm-offer-teaser-list.nm-offer-teaser-list-3 .nm-offer-teaser-item-link {
		margin-bottom: 0;
		width: calc((100% - var(--space-xs)) / 2);
	}

	.nm-module-offer-teaser .nm-offer-teaser-list.nm-offer-teaser-list-1 .nm-offer-teaser-item-link {
		width: 100%;
	}

	.nm-module-offer-teaser .nm-offer-teaser-list.nm-offer-teaser-list-3 .nm-offer-teaser-item-link:first-child {
		margin-bottom: var(--space-xl);
		width: 100%;
	}

	.nm-module-offer-teaser .nm-offer-teaser-list.nm-offer-teaser-list-2 .nm-offer-teaser-item-copy,
	.nm-module-offer-teaser .nm-offer-teaser-list.nm-offer-teaser-list-3 .nm-offer-teaser-item-copy {
		width: calc(100% - var(--space-m));
	}

	.nm-module-offer-teaser .nm-offer-teaser-list.nm-offer-teaser-list-1 .nm-offer-teaser-item {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
	}

	.nm-module-offer-teaser .nm-offer-teaser-list.nm-offer-teaser-list-1 .nm-offer-teaser-item-link .nm-offer-teaser-item-image-wrap {
		width: 50%;
	}

	.nm-module-offer-teaser .nm-offer-teaser-list.nm-offer-teaser-list-1 .nm-offer-teaser-item-link .nm-offer-teaser-item-info {
		width: calc(50% - var(--space-xl));
	}

	.nm-offer-teaser-list-2 .nm-module-offer-teaser__item--empty-disclaimer .nm-module-offer-teaser__asset-disclaimer {
		display: block;
	}

	.nm-offer-teaser-list-3 .nm-offer-teaser-item-link.nm-module-offer-teaser__item--empty-disclaimer ~ .nm-offer-teaser-item-link .nm-module-offer-teaser__asset-disclaimer,
	.nm-offer-teaser-list-3 .nm-offer-teaser-item-link:not(.nm-module-offer-teaser__item--empty-disclaimer) ~ .nm-offer-teaser-item-link .nm-module-offer-teaser__asset-disclaimer {
		display: block;
	}

	.nm-offer-teaser-list-3 .nm-offer-teaser-item-link.nm-module-offer-teaser__item--only-disclaimer ~ .nm-offer-teaser-item-link .nm-module-offer-teaser__asset-disclaimer {
		display: none;
	}
}

@media all and (min-width: 768px) {
	.nm-module-offer-teaser .nm-offer-teaser-list.nm-offer-teaser-list-3 .nm-offer-teaser-item-link,
	.nm-module-offer-teaser .nm-offer-teaser-list.nm-offer-teaser-list-3 .nm-offer-teaser-item-link:first-child {
		margin-bottom: 0;
		width: calc((100% - (2 * var(--space-xs))) / 3);
	}

	.nm-module-offer-teaser .nm-offer-teaser-list.nm-offer-teaser-list-2 .nm-offer-teaser-item-copy,
	.nm-module-offer-teaser .nm-offer-teaser-list.nm-offer-teaser-list-3 .nm-offer-teaser-item-copy {
		width: calc(100% - var(--space-xl));
	}

	.nm-module-offer-teaser__item--empty-disclaimer .nm-module-offer-teaser__asset-disclaimer {
		display: block !important;
	}
}
